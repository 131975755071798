import Button from 'components/Button'
import { useEffect } from 'react'
import css from './styles.module.scss'

export const TechWorks = () => {
  useEffect(() => {
    try {
      const video = document.getElementById('video-tech')
      if (!video) return
      video.controls = false
      video.play()
    } catch (e) {}
  }, [])
  return (
    <div className={css.techWorks}>
      <video className={css.video} autoPlay loop muted playsInline id="video-tech" controls={false}>
        <source src="/videos/tech-works.mp4" type="video/mp4" />
      </video>
      <h1 className={css.title}>Технические работы</h1>
      <p className={css.desc}>
        Загружаем обновления, и через 5-10 минут всё будет готово. Если вдруг процесс затянулся, просто дайте нам чуть
        больше времени
      </p>
      <Button
        className={css.button}
        color="blue"
        type="button"
        onClick={() => {
          window.location.reload()
        }}
      >
        Обновить сейчас
      </Button>
    </div>
  )
}
