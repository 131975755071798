import React from 'react'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import _ from 'lodash'
import useQuery from '../../lib/useQuery.js'
import { TechWorks } from 'components/TechWorks/index.js'

const SectionWrapper = ({ queryResult, queryName, loaderType = 'section', children }) => {
  const queryResultByInput = useQuery(queryName, { skip: !!queryResult })
  const queryResultHere = queryResult || queryResultByInput
  if (loaderType && queryResultHere.loading) {
    return <Loader type={loaderType} />
  }
  if (queryResultHere.error) {
    if (queryResultHere.error.humanMessage?.includes('На сайте ведутся технические')) {
      return <TechWorks />
    }
    return <Alert type="danger" message={queryResultHere.error.humanMessage} />
  }
  return _.isFunction(children) ? children(queryResultHere) : children
}

export const SectionWrapperWithQuery = ({ queryResult, loaderType = 'section', children }) => {
  if (loaderType && queryResult.loading) {
    return <Loader type={loaderType} />
  }
  if (queryResult.error) {
    if (queryResult.error.humanMessage?.includes('На сайте ведутся технические')) {
      return <TechWorks />
    }
    return <Alert type="danger" message={queryResult.error.humanMessage} />
  }
  return _.isFunction(children) ? children(queryResult) : children
}

export default SectionWrapper
