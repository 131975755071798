import Alert from 'components/Alert'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Loader from 'components/Loader'
import ResendEmailConfirmation from 'components/ResendEmailConfirmation'
import RichText from 'components/RichText'
import Segment from 'components/Segment'
import { TechWorks } from 'components/TechWorks'
import { getDashboardRoute, getHomeRoute, getSignInRoute, getWelcomeRoute } from 'lib/routes'
import useQuery from 'lib/useQuery'
import _ from 'lodash'
import NotFoundPage from 'pages/NotFoundPage'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

const PageWrapper = ({
  authorizedOnly,
  redirerctUnauthorized,
  redirerctAuthorized,
  checkAccess,
  checkExists,
  checkExistsTitle,
  checkExistsText,
  confirmedEmailOnly,
  // confirmedEmailOnlyTitle = 'Только для пользователей подтвердивших почту',
  // confirmedEmailOnlyText = 'Чтобы получить доступ к этой странице, подтвердите владение вашей почтой',
  queryResult = {},
  children,
}) => {
  const history = useHistory()
  const { me, loading: meLoading, error: meError } = useQuery('getMe')
  const loading = meLoading || queryResult.loading
  const error = meError || queryResult.error
  const queryResultCombined = { me, ...queryResult }
  let redirectTo = false
  if (!meLoading && me && redirerctAuthorized) {
    redirectTo =
      typeof redirerctAuthorized === 'string'
        ? redirerctAuthorized
        : me.welcomePageVisited
        ? getDashboardRoute()
        : getWelcomeRoute()
  }
  if (!meLoading && !me && redirerctUnauthorized) {
    redirectTo = typeof redirerctUnauthorized === 'string' ? redirerctUnauthorized : getSignInRoute()
  }
  useEffect(() => {
    if (redirectTo) history.push(redirectTo)
  }, [redirectTo, history])
  if (loading || redirectTo) return <Loader type="page" />
  if (error?.message === 'Observable cancelled prematurely') {
    console.warn(error)
    window.location.reload()
    return null
  }
  if (error) {
    const message = error.humanMessage || error.message || error.toString()
    if (message.includes('На сайте ведутся технические')) {
      return <TechWorks />
    }
    return (
      <Segment title="Ой, ошибка">
        <p>{message}</p>
      </Segment>
    )
  }
  if (!me && authorizedOnly)
    return (
      <Segment title="Только для авторизованных">
        <Alert style={{ marginBottom: 24 }}>
          Эта страница доступна только авторизованным пользователям. <Link to={getSignInRoute()}>Войдите</Link> или{' '}
          <Link to={getHomeRoute() + '#form'}>зарегистрируйтесь.</Link>
        </Alert>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
          <Button to={getHomeRoute() + '#form'}>Зарегистрироваться</Button>
          <Button to={getSignInRoute()}>Войти</Button>
        </div>
      </Segment>
    )
  if (checkAccess) {
    const accessDenied = !checkAccess(queryResultCombined)
    if (accessDenied)
      return (
        <Segment title="Доступ запрещён">
          <p>У вас недостаточно прав для просмотра этой страницы.</p>
        </Segment>
      )
  }
  if (checkExists) {
    const notExists = !checkExists(queryResultCombined)
    if (notExists) return <NotFoundPage title={checkExistsTitle} text={checkExistsText} />
  }
  if (confirmedEmailOnly && !me.emailConfirmed) {
    return (
      <RichText>
        <p>
          <Icon name="hand-note" />
        </p>
        <h1>Необходимо подтвердить почту</h1>
        <p>Эта страница доступна только тем, кто подтвердил свою почту.</p>
        <p className="secondary">Письмо для подтверждения почты было отправлено вам по адресу {me.email}.</p>
        <br />
        <ResendEmailConfirmation />
      </RichText>
    )
  }
  return _.isFunction(children) ? children(queryResultCombined) : children
}

export default PageWrapper
